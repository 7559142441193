<template>
  <b-container class="main">
    <b-table
      striped
      hover
      :items="remonts"
    ></b-table>
    <b-row>
      <b-form-input id="findSerial" v-model="serialNumber" placeholder="Введите серийный номер"></b-form-input>
    </b-row>
    <b-row class='mt-3'>
      <b-col>
        <b-button v-on:click="getScales(serialNumber)" align="center" class="findButton" variant="primary">Найти</b-button>
      </b-col>
      <b-col>
        <b-button v-on:click="getScalesByCompany(companyID)" align="center" class="findButton" variant="primary">Найти по компании</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="scales" class="">
        <b-table
        striped
        hover
        :items="scales"
        @row-clicked="onRowClicked"
        ></b-table>
      </b-col>
    </b-row>
    <b-row v-if="notFoundScales">
      <b-col align="left" class="mt-2">
        <span>Весы не найдены.</span>
        <div class="mt-1">
          <b-button v-b-modal.modal-1 align="center" id="createButton" variant="primary">Добавить</b-button>
        </div>
      </b-col>
    </b-row>
  <b-modal id="modal-1" title="Добавить весы" hide-footer>
    <b-form @submit="addScale">
    <b-form-group
        id="input-group-1"
        label="Серийный номер"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="serialNumber"
          type="text"
          placeholder="Введите серийный номер"
          required
        ></b-form-input>
      </b-form-group>
      <div class="mt-3">
          <b-form-select
          id="input-1"
          v-model="formAddScale.model"
          :options="options"
          placeholder="Выберите модель весов"
          required
        ></b-form-select>
      </div>
      <div class="mt-2">
        <b-button type="submit" variant="primary">Добавить кассу</b-button>
      </div>
    </b-form>
  </b-modal>
  <b-modal id="modal-2" v-model='addRemont' title="Добавить неисправность" hide-footer>
    <b-form @submit="addRemontForm">
    <b-form-group
        id="input-group-serial"
        label="Серийный номер"
        label-for="input-serial"
      >
        <b-form-input
          id="input-serial"
          v-model="serialNumber"
          type="text"
          placeholder="Введите серийный номер"
          required
        ></b-form-input>
      </b-form-group>
    <b-form-group
        id="input-group-error"
        label="Неисправность"
        label-for="input-error"
      >
        <b-form-input
          id="input-error"
          v-model="remont.description"
          type="text"
          placeholder="Введите неисправность"
          required
        ></b-form-input>
      </b-form-group>

      <div class="mt-2">
        <b-button type="submit" variant="primary">Добавить неисправность</b-button>
      </div>
    </b-form>
  </b-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'FindField',
  props: {
  },
  data: () => ({
    scales: [],
    errors: [],
    notFoundScales: false,
    addRemont: false,
    options: [
      {value: null, text: "Выберите модель весов"},
      {value: 'A', text: "Модель 1"}
    ],
    remonts: [],
    remont: {
      serialNumber: '',
      description: ''
    },
    formAddScale: {
      serialNumber: '',
      model: ''
    }
  }),
  methods: {
    getScales(serialNumber) {
      this.notFoundScales = false;
      let params = {
        IBLOCK_TYPE_ID: 'lists',
        IBLOCK_ID: 32,
        FILTER: {
          NAME: serialNumber
        }
      };
      axios.post('https://b24-wnlrf7.bitrix24.ru/rest/6/ghiirmr7jx42twcx/lists.element.get', params)
      .then(response => {
          this.scales = response.data.result;
          console.log(this.scales);
          let count = this.scales.length;
          console.log(count);
          if(count == 0) {
            this.notFoundScales = true;
          }
      });
    },
    getScalesByCompany(companyID) {
      console.log(companyID);
      alert('CompanyID');
    },
    onRowClicked(item) {
        this.addRemont = true;
        this.serialNumber = item.NAME;
    },
    addScale(event) {
      event.preventDefault();
      alert(JSON.stringify(this.remont));
    },
    addRemontForm(event) {
      event.preventDefault();
      this.remont.serialNumber = this.serialNumber;
      this.remonts.push(this.remont);
      this.scales = [];
      alert(JSON.stringify(this.remont));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
/* <style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.findButton {
  width: 25%;
}

</style> */
